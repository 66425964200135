import BeyoboNavbar from "../../../components/beyobonavbar";
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
//import DashboardBox from "../../../components/dashboardbox";
//import Notification from "../../../components/notification";
import CatalogProductContext from "context/CatalogProductContext";
import AuthContext from "context/AuthContext";
import OrderContext from "context/OrderContext";
import VariantContext from "context/VariantContext";
import DashboardDataGrid from "components/dashboarddatagrid";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

import AdminShipmentDataGrid from "./supplier_data_grids/AdminShipmentDataGrid";
import AdminVariantDataGrid from "./supplier_data_grids/AdminVariantDataGrid";
import AdminChannelDataGrid from "./supplier_data_grids/AdminChannelDataGrid";
import AdminPayoutDataGrid from "./supplier_data_grids/AdminPayoutDataGrid";
import AdminResumeDataGrid from "./supplier_data_grids/AdminResumeDataGrid";
import AdminFinAdDataGrid from "./supplier_data_grids/AdminFinAdDataGrid";
import AdminShipmentVariantDataGrid from "./supplier_data_grids/AdminShipmentVariantDataGrid";
import AdminViewsDataGrid from "./supplier_data_grids/AdminViewsDataGrid";
import AdminSkuDataGrid from "./supplier_data_grids/AdminSkuDataGrid";
import SkuSupplierDashboard from "./skuSupplierDashboard";
import ChannelSupplierDashboard from "./channelSupplierDashboard";
import ResumeSupplierDashboard from "./resumeSupplierDashboard";
import VariantsSupplierDashboard from "./variantsSupplierDashboard";
import FinanceAdSupplierDashboard from "./financeAdSupplierDashboard";
import ChannelSkuSupplierDashboard from "./channelSkuSupplierDashboard";
import OrderSupplierDashboard from "./orderSupplierDashboard";
import PayoutDetailsSupplierDashboard from "./payoutdetailsSupplierDashboard";
import ShipmentsSupplierDashboard from "./shipmentSupplierDashboard";
import ShipmentDetailsSupplierDashboard from "./shipmentDetailsSupplierDashboard";
import PurchaseOrderSupplierDashboard from "./purchaseOrderSupplierDashboard";
import LocationSupplierDashboard from "./locationSupplierDashboard";
import AdminChannelSkuDataGrid from "./supplier_data_grids/AdminChannelSkuDataGrid";
import AdminCustomerOrderDataGrid from "./supplier_data_grids/AdminCustomerOrderDataGrid";
import AdminPurchaseOrderDataGrid from "./supplier_data_grids/AdminPurchaseOrderDataGrid";
import AdminLocationDataGrid from "./supplier_data_grids/AdminLocationDataGrid";
import AdminWarehouseDataGrid from "./supplier_data_grids/AdminWarehouseDataGrid";
import AdminShipmentDetailsDataGrid from "./supplier_data_grids/AdminShipmentDetailsDataGrid";
import AccountSupplierDashboard from "./accountSupplierDashboard";
import AdminAccountDataGrid from "./supplier_data_grids/AdminAccountDataGrid";

const SupplierDashboard = () => {
  const [selectedAction, setSelectedAction] = useState({
    action: "",
    context: "",
    item: "",
  });

  // Configuration object with arrays of items for each section
  const sectionsConfig = {
    Catalogue: ["Variants", "SKU"],
    Channel: ["Channel", "Channel SKU"],
    Order: ["Customer Order", "Purchase Order"],
    "Marketplace Account": ["Account"],
    Location: ["Location", "Warehouse"],
    Resume: ["Resume"],
    "Finance Advertisement": ["Finance Advertisement"],
    "Finance Payouts": ["Payout Details"],
    Shipments: ["Shipments", "Shipment Details"],

    //    Orders: ["Order History", "Order Status"],
  };

  ///////////////////// CATALOGUE VARIANTS //////////////////////

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const handleVariantSubmit = async (e) => {
    e.preventDefault();
    const form = document.getElementById("catalogue_variant");
    let bodyFormData = new FormData(form);
    bodyFormData.append("testField", "testValue");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/product/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${authToken}`,
          },
          body: bodyFormData,
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log("Success:", data);
      } else {
        console.error("Error Response:", data);
      }
    } catch (error) {
      console.error("Network Error:", error);
    }
  };

  ///////////////////////////// SKU /////////////////////////////////////

  /////////////////////////// COMPANY NAME /////////////////////////////

  //const [platforms, setPlatforms] = useState([]);
  //const [selectedPlatform, setSelectedPlatform] = useState(null);
  //const [selectedChannel, setSelectedChannel] = useState('');

  //const handleChannelChange = (event) => {

  //    setSelectedChannel(event.target.value);
  //};

  //useEffect(() => {
  //    const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/channels/`;
  //    fetch(apiUrl, {
  //        headers: {
  //            'Authorization': `Token ${authToken}`,
  //        },
  //    })
  //        .then(response => response.json())
  //        .then(data => {
  //            const platformsMap = data.reduce((acc, channel) => {
  //                acc[channel.name] = channel.platform_image;
  //                return acc;
  //            }, {});

  //            const platformsArray = Object.entries(platformsMap).map(([name, logo]) => ({
  //                name,
  //                logo,
  //            }));

  //            setPlatforms(platformsArray);
  //        })
  //        .catch(error => console.error('Fetch error:', error));
  //},);

  //////////////////////////////// RESUME //////////////////////////////////////

  const { authToken } = useContext(AuthContext);

  const { variantnames } = useContext(VariantContext);

  // State to manage the visibility of the resume upload form popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Function to open the popup
  const handleOpenPopup = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setIsPopupOpen(true); // Open the popup
  };

  // Function to close the popup
  const handleClosePopup = () => setIsPopupOpen(false);

  // Initial form data state
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    notice_period_remaining: "",
    salary_expectations: "",
    joining_date: "",
  });

  // Handle form data change
  const handleFormDataChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  // State to store the selected file
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  // Handler for file changes
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first file
    if (file) {
      setFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleResumeSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission action

    let bodyFormData = new FormData();

    for (let key in formData) {
      bodyFormData.append(key, formData[key]);
    }

    bodyFormData.append("resume", file);

    const endpoint = `${process.env.REACT_APP_BASE_API_URL}/candidate/`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Token ${authToken}`,
        },
        body: bodyFormData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle errors, e.g., showing an error message
    }
  };

  /////////////////////////////// FINANCE ADVERTISEMENTS /////////////////////////////////////////

  const [formFinanceData, setFormFinanceData] = useState({
    company_id: "",
    channel: "",
    month: "",
    ad_cost: "",
  });

  // const handleFinanceInputChange = (e) => {
  //     const { name, value } = e.target;
  //     setFormFinanceData(prevFormFinanceData => ({
  //         ...prevFormFinanceData,
  //         [name]: value
  //     }));
  // };

  const [submitFinanceSuccess, setSubmitFinanceSuccess] = useState(false);
  const [submitFinanceError, setSubmitFinanceError] = useState(false);

  const handleFinanceSubmit = async (e) => {
    e.preventDefault();
    const form = document.getElementById("myFinanceForm");
    let bodyFormData = new FormData(form);
    bodyFormData.append("testField", "testValue");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/product/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${authToken}`,
          },
          body: bodyFormData,
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log("Success:", data);
      } else {
        console.error("Error Response:", data);
      }
    } catch (error) {
      console.error("Network Error:", error);
    }
  };

  // State for storing the list of companies fetched from the API
  const [companies, setCompanies] = useState([]);

  const [channels, setChannels] = useState([]);
  // State for storing the selected company
  const [selectedCompany, setSelectedCompany] = useState("");

  const [selectedChannel, setSelectedChannel] = useState("");

  const handleChannelChange = (event) => {
    setFormAdData((prevFormAdData) => ({
      ...prevFormAdData,
      selectedChannel: event.target.value,
    }));
  };

  // Fetch companies from the API when the component mounts

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_API_URL}/company/`, {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCompanies(data); // Assuming the API returns an array of products
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  // Handle changes to the selected company in the dropdown
  const handlevariantCompanyChange = (event) => {
    setFormVariantData((prevFormVariantData) => ({
      ...prevFormVariantData,
      selectedCompany: event.target.value,
    }));
  };

  const handleChannelCompanyChange = (event) => {
    setFormChannelData((prevFormChannelData) => ({
      ...prevFormChannelData,
      selectedCompany: event.target.value,
    }));
  };

  const handleAdCompanyChange = (event) => {
    const newSelectedCompany = event.target.value;

    // Update form data with the new selected company
    setFormAdData((prevFormAdData) => ({
      ...prevFormAdData,
      selectedCompany: newSelectedCompany,
    }));

    // Check if a company was selected (not the placeholder option)
    if (newSelectedCompany) {
      // Fetch channels for the selected company
      fetchChannelsForCompany(newSelectedCompany);
    } else {
      // If no company is selected (e.g., the placeholder option),
      // clear the channels state
      setChannels([]);
    }
  };

  const fetchChannelsForCompany = (companyName) => {
    fetch(
      `${
        process.env.REACT_APP_BASE_API_URL
      }/channels/getChannelForCompany/?company_name=${encodeURIComponent(
        companyName
      )}`,
      {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setChannels(data); // Assuming the response is an array of channels
      })
      .catch((error) => {
        console.error("Error fetching channels:", error);
        setChannels([]);
      });
  };

  const handlePayoutCompanyChange = (event) => {
    setFormPayoutData((prevFormPayoutData) => ({
      ...prevFormPayoutData,
      selectedCompany: event.target.value,
    }));
  };

  const handleShipmentCompanyChange = (event) => {
    setFormShipmentData((prevFormShipmentData) => ({
      ...prevFormShipmentData,
      selectedCompany: event.target.value,
    }));
  };

  const handleShipmentVariantCompanyChange = (event) => {
    setFormShipmentVariantData((prevFormShipmentVariantData) => ({
      ...prevFormShipmentVariantData,
      selectedCompany: event.target.value,
    }));
  };

  const handleSkuCompanyChange = (event) => {
    setFormSkuData((prevFormSkuData) => ({
      ...prevFormSkuData,
      selectedCompany: event.target.value,
    }));
  };

  const handleVariantChange = (selectedOptions) => {
    // Assuming each option is {id, name}, and your state's selectedVariants should accumulate these objects
    setFormSkuData((prevFormSkuData) => ({
      ...prevFormSkuData,
      selectedVariants: selectedOptions,
    }));
  };

  const [formVariantData, setFormVariantData] = useState({
    country: "",
    name: "",
    description: "",
    variant_code: "",
    hsn: "",

    selectedCompany: "",
  });

  const [formSkuData, setFormSkuData] = useState({
    name: "",
    sku_code: "",
    selectedCompany: "",

    selectedVariants: [],
    selectedChannel: "",
  });

  /*asin: '',*/

  const [formChannelData, setFormChannelData] = useState({
    name: "",
    platform: "",
    oms_channel_id: "",
    selectedCompany: "",
    selectedSkus: [], // Array to store selected SKUs
  });

  const [formAdData, setFormAdData] = useState({
    platform: "",
    ad_month: "",
    adcost: "",
    selectedCompany: "",
    selectedChannel: "",
  });

  const [formPayoutData, setFormPayoutData] = useState({
    payout_date: "",
    amount: "",
    currency: "",
    commission: "",
    commission_basis: "",
    narration: "",
    status: "",
    //type:'',
    selectedCompany: "",
  });

  const [formShipmentData, setFormShipmentData] = useState({
    order_id: "",
    awb: "",
    dispatched_date: "",
    received_date: "",
    freight_charge: "",
    custom_duty: "",
    conversion_rate: "",
    supplier_currency: "",
    receiver_currency: "",
    shipper_name: "",
    state: "",
    pickup_address: "",
    delivery_address: "",
    weight_in_kgs: "",
    volumetric_weight_in_kgs: "",
    selectedCompany: "",
  });

  const [formShipmentVariantData, setFormShipmentVariantData] = useState({
    shipment_id: "",
    variant_id: "",
    ordered_quantity: "",
    price: "",
    dispatched_quantity: "",
    received_quantity: "",
    duty_percentage_applicable: "",
    freight_per_pc: "",
    duty_per_pc: "",
    box_charges_pc: "",
    selectedCompany: "",
  });

  ////// VIEWS
  const [formViewsData, setFormViewsData] = useState({
    name: "",
    platform: "",
    oms_channel_id: "",
    selectedCompany: "",
  });

  const handlevariantInputChange = (e) => {
    const { name, value } = e.target;
    setFormVariantData((prevFormVariantData) => ({
      ...prevFormVariantData,
      [name]: value,
    }));
  };

  const handleSkuInputChange = (event) => {
    const { name, value } = event.target;
    setFormSkuData((prevFormSkuData) => ({
      ...prevFormSkuData,
      [name]: value,
    }));
  };

  const handleChannelInputChange = (e) => {
    const { name, value } = e.target;
    setFormChannelData((prevFormChannelData) => ({
      ...prevFormChannelData,
      [name]: value,
    }));
  };

  const handleAdInputChange = (e) => {
    const { name, value } = e.target;
    setFormAdData((prevFormAdData) => ({
      ...prevFormAdData,
      [name]: value,
    }));
  };

  const handlePayoutInputChange = (e) => {
    const { name, value } = e.target;
    setFormPayoutData((prevFormPayoutData) => ({
      ...prevFormPayoutData,
      [name]: value,
    }));
  };

  const handleShipmentInputChange = (e) => {
    const { name, value } = e.target;
    setFormShipmentData((prevFormShipmentData) => ({
      ...prevFormShipmentData,
      [name]: value,
    }));
  };

  const handleShipmentVariantInputChange = (e) => {
    const { name, value } = e.target;
    setFormShipmentVariantData((prevFormShipmentVariantData) => ({
      ...prevFormShipmentVariantData,
      [name]: value,
    }));
  };

  const handlevariantSubmit = async (event) => {
    event.preventDefault();

    let bodyFormData = new FormData();
    bodyFormData.append("company_name", formVariantData.selectedCompany); // Key to match your backend expectation

    for (let key in formVariantData) {
      if (key !== "selectedCompany") {
        // Exclude the 'selectedCompany' from the FormData
        bodyFormData.append(key, formVariantData[key]);
      }
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/product/create_with_company_name/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${authToken}`,
          },
          body: bodyFormData,
        }
      );

      if (response.ok) {
        setSubmitSuccess(true);
        setSubmitError(false);
        // Reset form data if necessary
        setFormVariantData({
          country: "",
          name: "",
          description: "",
          variant_code: "",
          hsn: "",

          selectedCompany: "",
        }); /*asin: '',*/
      } else {
        setSubmitSuccess(false);
        setSubmitError(true);
      }
    } catch (error) {
      console.error("Failed to submit form", error);
      setSubmitSuccess(false);
      setSubmitError(true);
    }
  };

  const [shipmentVariants, setShipmentVariants] = useState([]);
  useEffect(() => {
    // Assume this fetches your variants and sets them
    fetch(`${process.env.REACT_APP_BASE_API_URL}/variantname/`, {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const variantsWithLabel = data.map((variant) => ({
          ...variant,
          label: variant.name, // Ensure your objects have an 'id' and 'name' property
        }));
        setShipmentVariants(variantsWithLabel);
      })
      .catch((error) =>
        console.error("There was a problem with the fetch operation:", error)
      );
  }, [authToken]);

  const handleVariantClick = (clickedVariant) => {
    const variantExists = formSkuData.selectedVariants.some(
      (variant) => variant.id === clickedVariant.id
    );
    console.log(`Variant exists: ${variantExists}`, clickedVariant);

    if (variantExists) {
      setFormSkuData((prevFormSkuData) => {
        const updatedVariants = prevFormSkuData.selectedVariants.filter(
          (variant) => variant.id !== clickedVariant.id
        );
        console.log("Removing variant, updated list:", updatedVariants);
        return { ...prevFormSkuData, selectedVariants: updatedVariants };
      });
    } else {
      setFormSkuData((prevFormSkuData) => {
        const updatedVariants = [
          ...prevFormSkuData.selectedVariants,
          clickedVariant,
        ];
        console.log("Adding variant, updated list:", updatedVariants);
        return { ...prevFormSkuData, selectedVariants: updatedVariants };
      });
    }
  };

  useEffect(() => {
    // Fetch channels from the API
    fetch(`${process.env.REACT_APP_BASE_API_URL}/channels/`, {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setChannels(data); // Assuming the API returns an array of channels
      })
      .catch((error) => console.error("Failed to fetch channels", error));
  }, [authToken]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormSkuData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSkuSubmit = async (event) => {
    event.preventDefault();

    let bodyFormData = new FormData();
    // Append form data as before
    Object.entries(formSkuData).forEach(([key, value]) => {
      if (key === "selectedVariants") {
        bodyFormData.append(key, JSON.stringify(value));
      } else {
        bodyFormData.append(key, value);
      }
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/sku/`,
        {
          method: "POST",
          headers: { Authorization: `Token ${authToken}` },
          body: bodyFormData,
        }
      );

      if (response.ok) {
        setSubmitSuccess(true);
        setSubmitError(false);
        // Reset form state here if necessary
      } else {
        throw new Error("Submission failed");
      }
    } catch (error) {
      console.error("Failed to submit form", error);
      setSubmitSuccess(false);
      setSubmitError(true);
    }
  };

  const [selectedVariants, setSelectedVariants] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [displayOptions, setDisplayOptions] = useState(false);
  const [skuVariants, setSkuVariants] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [displayLimit, setDisplayLimit] = useState(15);
  const inputRef = useRef(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_API_URL}/sku/`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const formattedData = data.map((item, index) => ({
          ...item,
          id: index, // Assuming your data might not have `id`, adding it for demonstration
          isChecked: false,
        }));
        setSkuVariants(formattedData);
        setFilteredOptions(formattedData.slice(0, displayLimit));
      })
      .catch((error) => console.error("Failed to fetch SKUs:", error));
  }, [authToken, displayLimit]);

  const handleInputChanges = (event) => {
    const value = event.target.value;
    setInputValue(value);
    filterOptions(value);
  };

  const filterOptions = (value) => {
    const filtered = value
      ? skuVariants.filter((variant) =>
          variant.name.toLowerCase().includes(value.toLowerCase())
        )
      : skuVariants;
    setFilteredOptions(filtered.slice(0, displayLimit));
  };

  const handleFocus = () => {
    setDisplayOptions(true);
    filterOptions(inputValue);
  };

  const handleBlur = () => {
    setTimeout(() => setDisplayOptions(false), 200);
  };

  const handleOptionClick = (variant) => {
    const isSelected = skuVariants.find((v) => v.id === variant.id).isChecked;
    setSkuVariants(
      skuVariants.map((v) =>
        v.id === variant.id ? { ...v, isChecked: !isSelected } : v
      )
    );
    if (!isSelected) {
      setSelectedVariants([...selectedVariants, variant]);
    } else {
      setSelectedVariants(selectedVariants.filter((v) => v.id !== variant.id));
    }
  };

  const handleShowMore = () => {
    setDisplayLimit((currentLimit) => currentLimit + 10);
  };

  const handleChannelSubmit = async (event) => {
    event.preventDefault();

    // Preparing FormData to include text fields as well as the selected SKUs
    let bodyFormData = new FormData();
    for (let key in formChannelData) {
      if (key !== "selectedSkus") {
        // Handle all keys except 'selectedSkus' normally
        bodyFormData.append(key, formChannelData[key]);
      }
    }
    // For 'selectedSkus', append each SKU separately to the FormData
    // This is necessary because some backends expect arrays to be submitted with each item as a separate entry
    formChannelData.selectedSkus.forEach((skuId) => {
      bodyFormData.append("selectedSkus", skuId);
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/channels/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            // Don't set 'Content-Type' to 'multipart/form-data' here
            // The browser will automatically set it, including the boundary parameter
          },
          body: bodyFormData,
        }
      );

      if (response.ok) {
        // Handle successful submission here
        setSubmitSuccess(true);
        setSubmitError(false);
        // Optionally, reset form data and selected SKUs after successful submission
        setFormChannelData({
          name: "",
          platform: "",
          oms_channel_id: "",
          selectedCompany: "",
          selectedSkus: [],
        });
      } else {
        // Handle HTTP errors here
        setSubmitSuccess(false);
        setSubmitError(true);
        // Optionally, log or display error information
        const errorText = await response.text();
        console.error("Submission failed:", errorText);
      }
    } catch (error) {
      // Handle network or other asynchronous errors here
      console.error("Failed to submit form:", error);
      setSubmitSuccess(false);
      setSubmitError(true);
    }
  };

  const handleAdSubmit = async (event) => {
    event.preventDefault();

    let bodyFormData = new FormData();
    bodyFormData.append("company_name", formAdData.selectedCompany); // Key to match your backend expectation
    bodyFormData.append("channel_name", formAdData.selectedChannel);
    for (let key in formAdData) {
      if (key !== "selectedCompany" || key !== "selectedChannel") {
        // Exclude the 'selectedCompany' from the FormData
        bodyFormData.append(key, formAdData[key]);
      }
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/advertisement/create_with_company_name/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${authToken}`,
          },
          body: bodyFormData,
        }
      );

      if (response.ok) {
        setSubmitSuccess(true);
        setSubmitError(false);
        // Reset form data if necessary
        setFormAdData({
          platform: "",
          ad_month: "",
          adcost: "",
          selectedCompany: "",
          selectedChannel: "",
        });
      } else {
        setSubmitSuccess(false);
        setSubmitError(true);
      }
    } catch (error) {
      console.error("Failed to submit form", error);
      setSubmitSuccess(false);
      setSubmitError(true);
    }
  };

  const handlePayoutSubmit = async (event) => {
    event.preventDefault();

    let bodyFormData = new FormData();
    bodyFormData.append("company_name", formPayoutData.selectedCompany); // Key to match your backend expectation

    for (let key in formPayoutData) {
      if (key !== "selectedCompany") {
        // Exclude the 'selectedCompany' from the FormData
        bodyFormData.append(key, formPayoutData[key]);
      }
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/payout/create_with_company_name/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${authToken}`,
          },
          body: bodyFormData,
        }
      );

      if (response.ok) {
        setSubmitSuccess(true);
        setSubmitError(false);
        // Reset form data if necessary
        setFormPayoutData({
          payout_date: "",
          amount: "",
          currency: "",
          commission: "",
          commission_basis: "",
          narration: "",
          status: "",
          //type:'',
          selectedCompany: "",
        });
      } else {
        setSubmitSuccess(false);
        setSubmitError(true);
      }
    } catch (error) {
      console.error("Failed to submit form", error);
      setSubmitSuccess(false);
      setSubmitError(true);
    }
  };

  const handleShipmentSubmit = async (event) => {
    event.preventDefault();

    let bodyFormData = new FormData();
    bodyFormData.append("company_name", formShipmentData.selectedCompany); // Key to match your backend expectation

    for (let key in formShipmentData) {
      if (key !== "selectedCompany") {
        // Exclude the 'selectedCompany' from the FormData
        bodyFormData.append(key, formShipmentData[key]);
      }
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/adminshipment/create_with_company_name/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${authToken}`,
          },
          body: bodyFormData,
        }
      );

      if (response.ok) {
        setSubmitSuccess(true);
        setSubmitError(false);
        // Reset form data if necessary
        setFormShipmentData({
          order_id: "",
          awb: "",
          dispatched_date: "",
          received_date: "",
          freight_charge: "",
          custom_duty: "",
          conversion_rate: "",
          supplier_currency: "",
          receiver_currency: "",
          shipper_name: "",
          state: "",
          pickup_address: "",
          delivery_address: "",
          weight_in_kgs: "",
          volumetric_weight_in_kgs: "",
          selectedCompany: "",
        });
      } else {
        setSubmitSuccess(false);
        setSubmitError(true);
      }
    } catch (error) {
      console.error("Failed to submit form", error);
      setSubmitSuccess(false);
      setSubmitError(true);
    }
  };

  const handleShipmentVariantSubmit = async (event) => {
    event.preventDefault();

    let bodyFormData = new FormData();
    bodyFormData.append(
      "company_name",
      formShipmentVariantData.selectedCompany
    ); // Key to match your backend expectation

    for (let key in formShipmentVariantData) {
      if (key !== "selectedCompany") {
        // Exclude the 'selectedCompany' from the FormData
        bodyFormData.append(key, formShipmentVariantData[key]);
      }
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/adminshipmentvariant/create_with_company_name/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${authToken}`,
          },
          body: bodyFormData,
        }
      );

      if (response.ok) {
        setSubmitSuccess(true);
        setSubmitError(false);
        // Reset form data if necessary
        setFormShipmentVariantData({
          shipment_id: "",
          variant_id: "",
          ordered_quantity: "",
          price: "",
          dispatched_quantity: "",
          received_quantity: "",
          duty_percentage_applicable: "",
          freight_per_pc: "",
          duty_per_pc: "",
          box_charges_pc: "",
          selectedCompany: "",
        });
      } else {
        setSubmitSuccess(false);
        setSubmitError(true);
      }
    } catch (error) {
      console.error("Failed to submit form", error);
      setSubmitSuccess(false);
      setSubmitError(true);
    }
  };

  // const [platforms, setPlatforms] = useState([]);
  // const [selectedPlatform, setSelectedPlatform] = useState(null);
  // const [selectedChannel, setSelectedChannel] = useState('');

  // const handleChannelChange = (event) => {

  //     setSelectedChannel(event.target.value);
  // };

  // useEffect(() => {
  //     const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/channels/`;
  //     fetch(apiUrl, {
  //         headers: {
  //             'Authorization': `Token ${authToken}`,
  //         },
  //     })
  //         .then(response => response.json())
  //         .then(data => {
  //             const platformsMap = data.reduce((acc, channel) => {
  //                 acc[channel.name] = channel.platform_image;
  //                 return acc;
  //             }, {});

  //             const platformsArray = Object.entries(platformsMap).map(([name, logo]) => ({
  //                 name,
  //                 logo,
  //             }));

  //             setPlatforms(platformsArray);
  //         })
  //         .catch(error => console.error('Fetch error:', error));
  // },);

  // Component mapping based on action, context, and item
  const componentMapping = {
    //////////////////////////// ADD BUTTON ACTIONS ///////////////////////////////////////

    add: {
      Catalogue: {
        /////////////////////////////// VARIANTS ////////////////////////////////////////////

        Variants: () => (
          <>
            <VariantsSupplierDashboard></VariantsSupplierDashboard>
          </>
        ),

        ///////////////////////////////// SKU  ///////////////////////////////////////////
        SKU: () => (
          <>
            <SkuSupplierDashboard></SkuSupplierDashboard>
          </>
        ),

        ////////////////////////// COMPANY NAME /////////////////////////////////////////

        // "Company Name": () => <>
        //     <div className="items-center">
        //         <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">Select platform</label>
        //         {/*<select id="channel-select" value={selectedChannel} class="mt-1 p-2 border-2 border-gray-300 rounded-lg cursor-pointer" onChange={handleChannelChange}>*/}

        //         {/*    <option value="">Select a Platform</option>*/}
        //         {/*    {platforms.map((platform) => (*/}
        //         {/*        <option value={platform.name} key={platform.name}>*/}
        //         {/*            {platform.name}*/}
        //         {/*        </option>*/}
        //         {/*    ))}*/}
        //         {/*</select>*/}
        //     </div>
        // </>,
      },

      //////////////////////// CHANNEL ///////////////////////////////////

      Channel: {
        Channel: () => (
          <>
            <ChannelSupplierDashboard></ChannelSupplierDashboard>
          </>
        ),

        "Channel SKU": () => (
          <>
            <ChannelSkuSupplierDashboard></ChannelSkuSupplierDashboard>
          </>
        ),
      },

      //////////////////////// ORDER ///////////////////////////////////

      Order: {
        "Customer Order": () => (
          <>
            <OrderSupplierDashboard></OrderSupplierDashboard>
          </>
        ),

        "Purchase Order": () => (
          <>
            <PurchaseOrderSupplierDashboard></PurchaseOrderSupplierDashboard>
          </>
        ),
      },

      //////////////////////// Account ///////////////////////////////////

      "Marketplace Account": {
        Account: () => (
          <>
            <AccountSupplierDashboard></AccountSupplierDashboard>
          </>
        ),
      },

      //////////////////////// LOCATION ///////////////////////////////////

      Location: {
        Location: () => (
          <>
            <LocationSupplierDashboard></LocationSupplierDashboard>
          </>
        ),

        Warehouse: () => <></>,
      },

      //////////////////////// RESUME ///////////////////////////////////

      Resume: {
        Resume: () => (
          <>
            <ResumeSupplierDashboard></ResumeSupplierDashboard>
          </>
        ),
      },

      /////////////////////////////// FINANCE ADVERTISEMENTS ////////////////////////////////////////

      "Finance Advertisement": {
        "Finance Advertisement": () => (
          <>
            <FinanceAdSupplierDashboard></FinanceAdSupplierDashboard>
          </>
        ),
      },

      ///////////////////////// FINANCE PAYOUTS /////////////////////////////////////////

      "Finance Payouts": {
        "Payout Details": () => (
          <>
            <PayoutDetailsSupplierDashboard></PayoutDetailsSupplierDashboard>
          </>
        ),
      },

      ////////////////////////// SHIPMENTS //////////////////////////////

      Shipments: {
        Shipments: () => (
          <>
            <ShipmentsSupplierDashboard></ShipmentsSupplierDashboard>
          </>
        ),

        /////////////////////////// SHIPMENT VARIANTS //////////////////////////////////////

        "Shipment Details": () => (
          <>
            <ShipmentDetailsSupplierDashboard></ShipmentDetailsSupplierDashboard>
          </>
        ),

        //////////////////////// VIEWS ///////////////////////////////////
      },
    },

    /////////////////////// CHANGE BUTTON ACTIONS ////////////////////////////////////////

    change: {
      Catalogue: {
        Variants: () => (
          <>
            <button
              onClick={() =>
                setSelectedAction({
                  action: "add",
                  context: "Catalogue",
                  item: "Variants",
                })
              }
              className="my-4 rounded bg-green-500 py-2 px-4 font-bold text-white hover:bg-green-700"
            >
              Add Variant
            </button>
            <AdminVariantDataGrid></AdminVariantDataGrid>
          </>
        ),

        SKU: () => (
          <>
            <button
              onClick={() =>
                setSelectedAction({
                  action: "add",
                  context: "Catalogue",
                  item: "SKU",
                })
              }
              className="my-4 rounded bg-green-500 py-2 px-4 font-bold text-white hover:bg-green-700"
            >
              Add SKU
            </button>
            <AdminSkuDataGrid></AdminSkuDataGrid>
          </>
        ),
      },

      Channel: {
        Channel: () => (
          <>
            <button
              onClick={() =>
                setSelectedAction({
                  action: "add",
                  context: "Channel",
                  item: "Channel",
                })
              }
              className="my-4 rounded bg-green-500 py-2 px-4 font-bold text-white hover:bg-green-700"
            >
              Add Channel
            </button>
            <AdminChannelDataGrid></AdminChannelDataGrid>
          </>
        ),
      },

      Resume: {
        Resume: () => (
          <>
            <button
              onClick={() =>
                setSelectedAction({
                  action: "add",
                  context: "Resume",
                  item: "Resume",
                })
              }
              className="my-4 rounded bg-green-500 py-2 px-4 font-bold text-white hover:bg-green-700"
            >
              Add Resume
            </button>
            <AdminResumeDataGrid></AdminResumeDataGrid>
          </>
        ),
      },

      "Finance Advertisement": {
        "Finance Advertisement": () => (
          <>
            <button
              onClick={() =>
                setSelectedAction({
                  action: "add",
                  context: "Finance Advertisement",
                  item: "Finance Advertisement",
                })
              }
              className="my-4 rounded bg-green-500 py-2 px-4 font-bold text-white hover:bg-green-700"
            >
              Add Finance Advertisement
            </button>
            <AdminFinAdDataGrid></AdminFinAdDataGrid>
          </>
        ),
      },

      "Finance Payouts": {
        "Payout Details": () => (
          <>
            <button
              onClick={() =>
                setSelectedAction({
                  action: "add",
                  context: "Finance Payouts",
                  item: "Payout Details",
                })
              }
              className="my-4 rounded bg-green-500 py-2 px-4 font-bold text-white hover:bg-green-700"
            >
              Add Payout
            </button>
            <AdminPayoutDataGrid></AdminPayoutDataGrid>
          </>
        ),
      },

      Shipments: {
        Shipments: () => (
          <>
            <button
              onClick={() =>
                setSelectedAction({
                  action: "add",
                  context: "Shipments",
                  item: "Shipments",
                })
              }
              className="my-4 rounded bg-green-500 py-2 px-4 font-bold text-white hover:bg-green-700"
            >
              Add Shipment
            </button>

            <AdminShipmentDataGrid></AdminShipmentDataGrid>
          </>
        ),

        "Shipment Details": () => (
          <>
            <button
              onClick={() =>
                setSelectedAction({
                  action: "add",
                  context: "Shipments",
                  item: "Shipment Variants",
                })
              }
              className="my-4 rounded bg-green-500 py-2 px-4 font-bold text-white hover:bg-green-700"
            >
              Add Shipment Details
            </button>
            <AdminShipmentVariantDataGrid></AdminShipmentVariantDataGrid>
          </>
        ),
      },

      // Add more specific components for other actions and contexts as needed
    },

    View: {
      Catalogue: {
        Variants: () => (
          <>
            <AdminVariantDataGrid></AdminVariantDataGrid>
          </>
        ),

        SKU: () => (
          <>
            <AdminSkuDataGrid></AdminSkuDataGrid>
          </>
        ),
      },

      Channel: {
        Channel: () => (
          <>
            <AdminChannelDataGrid></AdminChannelDataGrid>
          </>
        ),

        "Channel SKU": () => (
          <>
            <AdminChannelSkuDataGrid></AdminChannelSkuDataGrid>
          </>
        ),
      },

      Order: {
        //"Customer Order": () => <>
        //    <AdminCustomerOrderDataGrid></AdminCustomerOrderDataGrid>
        //</>,

        "Purchase Order": () => (
          <>
            <AdminPurchaseOrderDataGrid></AdminPurchaseOrderDataGrid>
          </>
        ),
      },

      "Marketplace Account": {
        Account: () => (
          <>
            <AdminAccountDataGrid></AdminAccountDataGrid>
          </>
        ),
      },

      //Location: {
      //    "Location": () => <>
      //        <AdminLocationDataGrid></AdminLocationDataGrid>
      //    </>,

      //    "Warehouse": () => <>
      //        <AdminWarehouseDataGrid></AdminWarehouseDataGrid>
      //    </>,
      //},

      Resume: {
        Resume: () => (
          <>
            <AdminResumeDataGrid></AdminResumeDataGrid>
          </>
        ),
      },

      "Finance Advertisement": {
        "Finance Advertisement": () => (
          <>
            <AdminFinAdDataGrid></AdminFinAdDataGrid>
          </>
        ),
      },

      "Finance Payouts": {
        "Payout Details": () => (
          <>
            <AdminPayoutDataGrid></AdminPayoutDataGrid>
          </>
        ),
      },

      Shipments: {
        Shipments: () => (
          <>
            <AdminShipmentDataGrid></AdminShipmentDataGrid>
          </>
        ),

        "Shipment Details": () => (
          <>
            <AdminShipmentDetailsDataGrid></AdminShipmentDetailsDataGrid>
          </>
        ),

        "Shipment Variants": () => (
          <>
            <AdminShipmentVariantDataGrid></AdminShipmentVariantDataGrid>
          </>
        ),
      },

      // Add more specific components for other actions and contexts as needed
    },
  };

  const handleActionClick = (action, context, item) => () => {
    setSelectedAction({ action, context, item });
  };

  const renderSidebarContent = () => {
    const { action, context, item } = selectedAction;
    // Dynamically determine the component to render based on the action, context, and item
    const Component = componentMapping[action]?.[context]?.[item];
    return Component ? <Component /> : <div>Select an action</div>;
  };

  //<h2 className="p-5 text-black text-2xl font-normal">Operation Panel</h2>

  return (
    <>
      <div className="flex-cols-2 my-5 flex w-full gap-5">
        {/* Left Sidebar Sections */}
        <div className="mx-5 w-1/3 gap-5">
          {Object.entries(sectionsConfig).map(([context, items]) => (
            <div key={context} className="my-5">
              <p className="w-full rounded bg-brand-300 p-3 text-white">
                {context}
              </p>
              <table className="w-full">
                <tbody>
                  {items.map((item) => (
                    <tr key={item}>
                      <td className="w-[75%] p-3 font-semibold">{item}</td>
                      <td className="w-[12.5%] items-center p-3">
                        <button
                          onClick={handleActionClick("add", context, item)}
                          className="flex items-center gap-2"
                        >
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#000000"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <title></title>{" "}
                              <g id="Complete">
                                {" "}
                                <g data-name="add" id="add-2">
                                  {" "}
                                  <g>
                                    {" "}
                                    <line
                                      fill="none"
                                      stroke="#0fd200"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      x1="12"
                                      x2="12"
                                      y1="19"
                                      y2="5"
                                    ></line>{" "}
                                    <line
                                      fill="none"
                                      stroke="#0fd200"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      x1="5"
                                      x2="19"
                                      y1="12"
                                      y2="12"
                                    ></line>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>
                          </svg>
                          Add
                        </button>
                      </td>
                      <td className="w-[12.5%] items-center p-3">
                        <button
                          onClick={handleActionClick("change", context, item)}
                          className="flex items-center gap-2"
                        >
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 -0.5 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.265 4.16231L19.21 5.74531C19.3978 5.9283 19.5031 6.17982 19.5015 6.44201C19.5 6.70421 19.3919 6.9545 19.202 7.13531L17.724 8.93531L12.694 15.0723C12.6069 15.1749 12.4897 15.2473 12.359 15.2793L9.75102 15.8793C9.40496 15.8936 9.10654 15.6384 9.06702 15.2943L9.18902 12.7213C9.19806 12.5899 9.25006 12.4652 9.33702 12.3663L14.15 6.50131L15.845 4.43331C16.1743 3.98505 16.7938 3.86684 17.265 4.16231Z"
                                stroke="#ffdd00"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>{" "}
                              <path
                                d="M5.5 18.2413C5.08579 18.2413 4.75 18.5771 4.75 18.9913C4.75 19.4056 5.08579 19.7413 5.5 19.7413V18.2413ZM19.2 19.7413C19.6142 19.7413 19.95 19.4056 19.95 18.9913C19.95 18.5771 19.6142 18.2413 19.2 18.2413V19.7413ZM14.8455 6.22062C14.6904 5.83652 14.2534 5.65082 13.8693 5.80586C13.4852 5.9609 13.2995 6.39796 13.4545 6.78206L14.8455 6.22062ZM17.8893 9.66991C18.2933 9.57863 18.5468 9.17711 18.4556 8.77308C18.3643 8.36904 17.9628 8.1155 17.5587 8.20678L17.8893 9.66991ZM5.5 19.7413H19.2V18.2413H5.5V19.7413ZM13.4545 6.78206C13.6872 7.35843 14.165 8.18012 14.8765 8.8128C15.6011 9.45718 16.633 9.95371 17.8893 9.66991L17.5587 8.20678C16.916 8.35198 16.3609 8.12551 15.8733 7.69189C15.3725 7.24656 15.0128 6.63526 14.8455 6.22062L13.4545 6.78206Z"
                                fill="#ffdd00"
                              ></path>{" "}
                            </g>
                          </svg>
                          Change
                        </button>
                      </td>
                      <td className="w-[12.5%] items-center p-3">
                        <button
                          onClick={handleActionClick("View", context, item)}
                          className="flex items-center gap-2"
                        >
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 -0.5 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.265 4.16231L19.21 5.74531C19.3978 5.9283 19.5031 6.17982 19.5015 6.44201C19.5 6.70421 19.3919 6.9545 19.202 7.13531L17.724 8.93531L12.694 15.0723C12.6069 15.1749 12.4897 15.2473 12.359 15.2793L9.75102 15.8793C9.40496 15.8936 9.10654 15.6384 9.06702 15.2943L9.18902 12.7213C9.19806 12.5899 9.25006 12.4652 9.33702 12.3663L14.15 6.50131L15.845 4.43331C16.1743 3.98505 16.7938 3.86684 17.265 4.16231Z"
                                stroke="#ffdd00"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>{" "}
                              <path
                                d="M5.5 18.2413C5.08579 18.2413 4.75 18.5771 4.75 18.9913C4.75 19.4056 5.08579 19.7413 5.5 19.7413V18.2413ZM19.2 19.7413C19.6142 19.7413 19.95 19.4056 19.95 18.9913C19.95 18.5771 19.6142 18.2413 19.2 18.2413V19.7413ZM14.8455 6.22062C14.6904 5.83652 14.2534 5.65082 13.8693 5.80586C13.4852 5.9609 13.2995 6.39796 13.4545 6.78206L14.8455 6.22062ZM17.8893 9.66991C18.2933 9.57863 18.5468 9.17711 18.4556 8.77308C18.3643 8.36904 17.9628 8.1155 17.5587 8.20678L17.8893 9.66991ZM5.5 19.7413H19.2V18.2413H5.5V19.7413ZM13.4545 6.78206C13.6872 7.35843 14.165 8.18012 14.8765 8.8128C15.6011 9.45718 16.633 9.95371 17.8893 9.66991L17.5587 8.20678C16.916 8.35198 16.3609 8.12551 15.8733 7.69189C15.3725 7.24656 15.0128 6.63526 14.8455 6.22062L13.4545 6.78206Z"
                                fill="#ffdd00"
                              ></path>{" "}
                            </g>
                          </svg>
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>

        {/* Right Sidebar Sections */}
        <div className="m-3 w-1/2 justify-between gap-5">
          {renderSidebarContent()}
        </div>
      </div>
    </>
  );
};

export default SupplierDashboard;

//{/* Resume upload form popup */ }
//{
//    isPopupOpen && (
//        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-100" id="my-modal">
//            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
//                <div className="mt-3">
//                    {/* Close button */}
//                    <button onClick={handleClosePopup} className="absolute top-0 right-0 rounded-full bg-red-200 px-2 py-1 m-1">
//                        X
//                    </button>
//                    <form onSubmit={(e) => e.preventDefault()}>
//                        <div className="mb-4">
//                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="candidateName">
//                                Candidate Name*
//                            </label>
//                            <input
//                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                                id="name"
//                                name="name"
//                                type="text"
//                                placeholder="Candidate Name"
//                                value={formData.name}
//                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
//                                required
//                            />
//                        </div>

//                        <div className="mb-4">
//                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="designation">
//                                Designation*
//                            </label>
//                            <input
//                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                                id="designation"
//                                name="designation"
//                                type="tel"
//                                placeholder="Designation"
//                                value={formData.designation}
//                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
//                                required
//                            />
//                        </div>

//                        <div className="mb-4">
//                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="noticePeriod">
//                                Notice Period Remaining*
//                            </label>
//                            <input
//                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                                id="notice_period_remaining"
//                                name="notice_period_remaining"
//                                type="text"
//                                placeholder="Notice Period Remaining"
//                                value={formData.notice_period_remaining}
//                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
//                                required
//                            />
//                        </div>

//                        <div className="mb-4">
//                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="salaryExpected">
//                                Salary Expected*
//                            </label>
//                            <input
//                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                                id="salary_expectations"
//                                name="salary_expectations"
//                                type="text"
//                                placeholder="Salary Expected"
//                                value={formData.salary_expectations}
//                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
//                                required
//                            />
//                        </div>

//                        <div className="mb-4">
//                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dateOfJoining">
//                                Date of joining*
//                            </label>
//                            <input
//                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                                id="joining_date"
//                                name="joining_date"
//                                type="date"
//                                placeholder="Date of joining"
//                                value={formData.joining_date}
//                                onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}
//                                required
//                            />
//                        </div>

//                        <div className="my-4">
//                            <label className="block text-gray-700 text-sm font-bold mb-2">
//                                Upload Resume *
//                            </label>
//                            <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-blue-500 border-dotted rounded-lg">
//                                <div className="space-y-1 text-center">
//                                    {/* Display preview if there's a file */}
//                                    {previewUrl && (
//                                        <img src={previewUrl} alt="Preview" className="mx-auto mb-3" style={{ maxWidth: '200px', maxHeight: '200px' }} />
//                                    )}
//                                    <div className="flex text-sm text-gray-600">
//                                        <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
//                                            <span>Upload a file</span>
//                                            <input
//                                                id="file-upload"
//                                                name="resume"
//                                                type="file"
//                                                className="sr-only"
//                                                onChange={handleFileChange}
//                                                accept="image/png, image/jpeg, image/gif"
//                                            />
//                                        </label>
//                                        <p className="pl-1">or drag and drop</p>
//                                    </div>
//                                    <p className="text-xs text-gray-500">
//                                        PNG, JPG, GIF up to 10MB
//                                    </p>
//                                </div>
//                            </div>
//                        </div>
//                        <div className="flex gap-5">
//                            <button onClick={handleSubmit} type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
//                                Submit
//                            </button>
//                        </div>
//                    </form>
//                </div>
//            </div>
//        </div>
//    )
//}

//<BeyoboNavbar />
//            <div className="bg-blue-600 text-white text-center p-2 leading-none">
//                <p className="text-sm md:text-base">
//                    This is currently beta app. Your feedback is of utmost importance.
//                </p>
//            </div>

//{/*<div className="my-5">*/ }
//{/*    <p className="p-3 w-full bg-brand-300 text-white rounded">Company Registration</p>*/ }

//{/*</div>*/ }

//{/*<div className="my-5">*/ }
//{/*    <p className="p-3 w-full bg-brand-300 text-white rounded">Catalogue</p>*/ }

//{/*    <table className="w-full">*/ }
//{/*        <tbody>*/ }
//{/*            <tr>*/ }
//{/*                <td className="w-[75%] p-3 font-semibold">Variants</td>*/ }
//{/*                <td className="w-[15%] items-center p-3"><button className="flex gap-2 items-center"><svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title></title> <g id="Complete"> <g data-name="add" id="add-2"> <g> <line fill="none" stroke="#0fd200" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line> <line fill="none" stroke="#0fd200" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line> </g> </g> </g> </g></svg>Add</button></td>*/ }
//{/*                <td className="w-[15%] items-center p-3"><button className="flex gap-2 items-center"><svg width="24px" height="24px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M17.265 4.16231L19.21 5.74531C19.3978 5.9283 19.5031 6.17982 19.5015 6.44201C19.5 6.70421 19.3919 6.9545 19.202 7.13531L17.724 8.93531L12.694 15.0723C12.6069 15.1749 12.4897 15.2473 12.359 15.2793L9.75102 15.8793C9.40496 15.8936 9.10654 15.6384 9.06702 15.2943L9.18902 12.7213C9.19806 12.5899 9.25006 12.4652 9.33702 12.3663L14.15 6.50131L15.845 4.43331C16.1743 3.98505 16.7938 3.86684 17.265 4.16231Z" stroke="#ffdd00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M5.5 18.2413C5.08579 18.2413 4.75 18.5771 4.75 18.9913C4.75 19.4056 5.08579 19.7413 5.5 19.7413V18.2413ZM19.2 19.7413C19.6142 19.7413 19.95 19.4056 19.95 18.9913C19.95 18.5771 19.6142 18.2413 19.2 18.2413V19.7413ZM14.8455 6.22062C14.6904 5.83652 14.2534 5.65082 13.8693 5.80586C13.4852 5.9609 13.2995 6.39796 13.4545 6.78206L14.8455 6.22062ZM17.8893 9.66991C18.2933 9.57863 18.5468 9.17711 18.4556 8.77308C18.3643 8.36904 17.9628 8.1155 17.5587 8.20678L17.8893 9.66991ZM5.5 19.7413H19.2V18.2413H5.5V19.7413ZM13.4545 6.78206C13.6872 7.35843 14.165 8.18012 14.8765 8.8128C15.6011 9.45718 16.633 9.95371 17.8893 9.66991L17.5587 8.20678C16.916 8.35198 16.3609 8.12551 15.8733 7.69189C15.3725 7.24656 15.0128 6.63526 14.8455 6.22062L13.4545 6.78206Z" fill="#ffdd00"></path> </g></svg>Change</button></td>*/ }
//{/*            </tr>*/ }
//{/*        </tbody>*/ }
//{/*    </table>*/ }
//{/*</div>*/ }

//{/*<div className="my-5">*/ }
//{/*    <p className="p-3 w-full bg-brand-300 text-white rounded">Catalogue</p>*/ }

//{/*    <table className="w-full">*/ }
//{/*        <tbody>*/ }
//{/*            <tr>*/ }
//{/*                <td className="w-[75%] p-3 font-semibold">Variants</td>*/ }
//{/*                <td className="w-[15%] items-center p-3"><button className="flex gap-2 items-center"><svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title></title> <g id="Complete"> <g data-name="add" id="add-2"> <g> <line fill="none" stroke="#0fd200" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line> <line fill="none" stroke="#0fd200" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line> </g> </g> </g> </g></svg>Add</button></td>*/ }
//{/*                <td className="w-[15%] items-center p-3"><button className="flex gap-2 items-center"><svg width="24px" height="24px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M17.265 4.16231L19.21 5.74531C19.3978 5.9283 19.5031 6.17982 19.5015 6.44201C19.5 6.70421 19.3919 6.9545 19.202 7.13531L17.724 8.93531L12.694 15.0723C12.6069 15.1749 12.4897 15.2473 12.359 15.2793L9.75102 15.8793C9.40496 15.8936 9.10654 15.6384 9.06702 15.2943L9.18902 12.7213C9.19806 12.5899 9.25006 12.4652 9.33702 12.3663L14.15 6.50131L15.845 4.43331C16.1743 3.98505 16.7938 3.86684 17.265 4.16231Z" stroke="#ffdd00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M5.5 18.2413C5.08579 18.2413 4.75 18.5771 4.75 18.9913C4.75 19.4056 5.08579 19.7413 5.5 19.7413V18.2413ZM19.2 19.7413C19.6142 19.7413 19.95 19.4056 19.95 18.9913C19.95 18.5771 19.6142 18.2413 19.2 18.2413V19.7413ZM14.8455 6.22062C14.6904 5.83652 14.2534 5.65082 13.8693 5.80586C13.4852 5.9609 13.2995 6.39796 13.4545 6.78206L14.8455 6.22062ZM17.8893 9.66991C18.2933 9.57863 18.5468 9.17711 18.4556 8.77308C18.3643 8.36904 17.9628 8.1155 17.5587 8.20678L17.8893 9.66991ZM5.5 19.7413H19.2V18.2413H5.5V19.7413ZM13.4545 6.78206C13.6872 7.35843 14.165 8.18012 14.8765 8.8128C15.6011 9.45718 16.633 9.95371 17.8893 9.66991L17.5587 8.20678C16.916 8.35198 16.3609 8.12551 15.8733 7.69189C15.3725 7.24656 15.0128 6.63526 14.8455 6.22062L13.4545 6.78206Z" fill="#ffdd00"></path> </g></svg>Change</button></td>*/ }
//{/*            </tr>*/ }
//{/*        </tbody>*/ }
//{/*    </table>*/ }
//{/*</div>*/ }

//{/* Learning Videos Section */ }
//{/* <div className="bg-white rounded-lg shadow p-5 mb-5">
//                        <h2 className="text-xl font-semibold mb-4">Learning Videos</h2>
//                        <ul className="list-disc list-inside">
//                            <li>Payment Reconciliation Guide <span className="text-red-500">NEW</span></li>
//                            <li>Catalogue process</li>
//                            <li>Shipments in Beyobo</li>
//                            <li>Click here for more videos</li>
//                        </ul>
//                    </div> */}

//{/* Activity Section */ }
//{/* <div className="bg-white rounded-lg shadow p-5">
//                        <h2 className="text-xl font-semibold mb-4">Activity</h2>

//                        <Notification></Notification>
//                        <Notification></Notification>
//                    </div> */}

//{/* Dashboard Boxes */ }

//{/*<div className="w-full flex flex-wrap gap-5 mt-5">*/ }
//{/*    <DashboardBox*/ }
//{/*        imgSrc="https://sellercentral.jennifer-in.com/assets/images/svg/shipmentvalue.svg"*/ }
//{/*        imgAlt="Shipment Value"*/ }
//{/*        currency="INR"*/ }
//{/*        description="Total Shipment Value"*/ }
//{/*    />*/ }
//{/*    <DashboardBox*/ }
//{/*        imgSrc="https://sellercentral.jennifer-in.com/assets/images/svg/amazoncredit.svg"*/ }
//{/*        imgAlt="Total Credit"*/ }
//{/*        currency="INR"*/ }
//{/*        description="Total Credit"*/ }

//{/*    />*/ }
//{/*    */ } {/*options={['Amazon', 'Flipkart', 'Meesho']} // Providing options here*/ }
//{/*    <DashboardBox*/ }
//{/*        imgSrc="https://sellercentral.jennifer-in.com/assets/images/svg/amountrecived.svg"*/ }
//{/*        imgAlt="Total Amazon Credit"*/ }
//{/*        currency="INR"*/ }
//{/*        description="Total Amount Received"*/ }
//{/*    />*/ }
//{/*    */ } {/* Add more DashboardBox components as needed */ }
//{/*</div>*/ }

//{/* Gross Sales Section */ }
//{/*<div className="my-5">*/ }
//{/*    <div className="bg-white rounded-lg shadow">*/ }
//{/*        <div className="flex justify-between p-5 items-center">*/ }
//{/*            <h2 className="text-xl font-semibold mb-4">Gross Sales</h2>*/ }
//{/*            <div className="col-md-3 col-xs-12 w-full md:w-1/4 px-2">*/ }
//{/*                <div className="relative" ref={dropdownRef}>*/ }
//{/*                    <button*/ }
//{/*                        className="text-left text-gray-700 w-full px-4 py-2 bg-white rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring"*/ }
//{/*                        onClick={() => setShowDropdown(!showDropdown)}*/ }
//{/*                    >*/ }
//{/*                        {selected}*/ }
//{/*                    </button>*/ }
//{/*                    {showDropdown && (*/ }
//{/*                        <div className="absolute right-0 w-full mt-2 bg-white rounded-md shadow-lg z-50">*/ }
//{/*                            {['THIS YEAR', 'THIS MONTH', 'LAST 30 DAYS', 'LAST MONTH', 'CUSTOM UPTO 180 DAYS'].map((item) => (*/ }
//{/*                                <a key={item} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleSelect(item)}>*/ }
//{/*                                    {item}*/ }
//{/*                                </a>*/ }
//{/*                            ))}*/ }
//{/*                        </div>*/ }
//{/*                    )}*/ }
//{/*                </div>*/ }
//{/*            </div>*/ }
//{/*        </div>*/ }

//{/*        <DashboardDataGrid></DashboardDataGrid>*/ }

//{/*        <hr className="bg-gray-300" />*/ }

//{/*        <div className="p-5"></div>*/ }
//{/*    </div>*/ }
//{/*</div>*/ }
